<template>
  <div class="cta-amigos">
    <div class="cta-amigos__ico">
      <img src="../../assets/img/ico/icon-smiley-32.png" alt="" />
    </div>
    <h3 class="cta-amigos__title">{{ $t("guest.doYouLike") }}</h3>
    <div class="cta-amigos__actions">
      <a
        :href="mailtoUrl"
        >{{ $t("guest.recommendHelloBB") }}</a
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "CtaAmigos",
  computed: {
    mailtoUrl() {
      const data = {
        subject: this.$t("guest.recommendHelloBBMailto.subject"),
        body: this.$t("guest.recommendHelloBBMailto.body"),
      };

      return `mailto:${this.toQueryString(data)}`;
    },
  },
  methods: {
    toQueryString(obj, prefix="?") {
      return prefix + (
        Object.entries(obj)
          .map(([key, value]) => encodeURIComponent(key) + "=" + encodeURIComponent(value))
          .join("&")
      );
    },
  },
};
</script>
